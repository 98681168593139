import React, { useEffect, useState, useRef } from "react";
import { Button, Modal, Input, message, Spin, Collapse } from "antd";
import Question from "./question";
const { Panel } = Collapse;

const { TextArea } = Input;

export default function () {
  const [sendText, setSendText] = useState("");
  const [questions, setQuestions] = useState([]);
  useEffect(() => {
    const footer = document.getElementById("footer");
    footer && footer.remove();
  }, []);
  const onChange = ({ target: { value } }) => {
    setSendText(value);
  };
  const clickSubmitHandler = async () => {
    if (!sendText || !sendText.trim()) {
      return;
    }
    setQuestions([sendText, ...questions]);
    setSendText("");
  };
  return (
    <div>
      <TextArea
        value={sendText}
        onChange={onChange}
        placeholder="请输入问题"
        autoSize={{ minRows: 3, maxRows: 10 }}
      />
      <Button onClick={clickSubmitHandler}>确定</Button>
      <Collapse defaultActiveKey={["1"]}>
        {questions.map((q, index) => (
          <Question key={q + "_" + index} question={q}></Question>
        ))}
      </Collapse>
      {/* <div dangerouslySetInnerHTML={{ __html: result }}></div> */}
    </div>
  );
}
