import React, { useEffect, useState, useRef } from "react";
import Style from "./bank.module.scss";
import { Upload, Button } from "antd";
import { read, utils } from "xlsx";

function convertCurrency(money) {
  //汉字的数字
  var cnNums = new Array(
    "零",
    "壹",
    "贰",
    "叁",
    "肆",
    "伍",
    "陆",
    "柒",
    "捌",
    "玖"
  );
  //基本单位
  var cnIntRadice = new Array("", "拾", "佰", "仟");
  //对应整数部分扩展单位
  var cnIntUnits = new Array("", "万", "亿", "兆");
  //对应小数部分单位
  var cnDecUnits = new Array("角", "分", "毫", "厘");
  //整数金额时后面跟的字符
  var cnInteger = "整";
  //整型完以后的单位
  var cnIntLast = "元";
  //最大处理的数字
  var maxNum = 999999999999999.9999;
  //金额整数部分
  var integerNum;
  //金额小数部分
  var decimalNum;
  //输出的中文金额字符串
  var chineseStr = "";
  //分离金额后用的数组，预定义
  var parts;
  // 传入的参数为空情况
  if (money == "") {
    return "";
  }
  money = parseFloat(money);
  if (money >= maxNum) {
    return "";
  }
  // 传入的参数为0情况
  if (money == 0) {
    chineseStr = cnNums[0] + cnIntLast + cnInteger;
    return chineseStr;
  }
  // 转为字符串
  money = money.toString();
  // indexOf 检测某字符在字符串中首次出现的位置 返回索引值（从0 开始） -1 代表无
  if (money.indexOf(".") == -1) {
    integerNum = money;
    decimalNum = "";
  } else {
    parts = money.split(".");
    integerNum = parts[0];
    decimalNum = parts[1].substr(0, 4);
  }
  //转换整数部分
  if (parseInt(integerNum, 10) > 0) {
    let zeroCount = 0;
    let IntLen = integerNum.length;
    for (let i = 0; i < IntLen; i++) {
      let n = integerNum.substr(i, 1);
      let p = IntLen - i - 1;
      let q = p / 4;
      let m = p % 4;
      if (n == "0") {
        zeroCount++;
      } else {
        if (zeroCount > 0) {
          chineseStr += cnNums[0];
        }
        zeroCount = 0;
        chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
      }
      if (m == 0 && zeroCount < 4) {
        chineseStr += cnIntUnits[q];
      }
    }
    // 最后+ 元
    chineseStr += cnIntLast;
  }
  // 转换小数部分
  if (decimalNum != "") {
    let decLen = decimalNum.length;
    for (let i = 0; i < decLen; i++) {
      let n = decimalNum.substr(i, 1);
      if (n != "0") {
        chineseStr += cnNums[Number(n)] + cnDecUnits[i];
      }
    }
  }
  if (chineseStr == "") {
    chineseStr += cnNums[0] + cnIntLast + cnInteger;
  } else if (decimalNum == "") {
    chineseStr += cnInteger;
  }

  return chineseStr;
}
export default ({ children }) => {
  const [data, setData] = useState([{
    日期: "2022/4/1",
    会计年度: 2022,
    期间: 4,
    凭证字: "记",
    凭证号: "1",
    原币金额: 312453.55,
    科目编码: "2204",
    科目全名: "待确认应收账款",
    币别: "美元",
    摘要: "收货款/0401",
    贷方金额: 1983517.63,
    制单: "廖丽",
    审核: "廖丽",
    过账: "李霜蓉",
    附件数: 0,
    来源系统: "总账",
    业务类型: "手工录入",
    审核状态: "已审核",
    作废状态: "未作废"
  }]);
  const uploadProps = {
    beforeUpload: (file) => {
      if (
        /^application\/vnd.openxmlformats-officedocument.spreadsheetml.sheet/.test(
          file.type
        )
      ) {
        let fileReader = new FileReader();
        fileReader.readAsBinaryString(file);
        fileReader.onload = (event) => {
          let data = event.target.result;
          let workbook = read(data, { type: "binary" });
          workbook.SheetNames.forEach((sheet) => {
            let rowObject = utils.sheet_to_row_object_array(
              workbook.Sheets[sheet]
            );
            // 凭证号 日期
            let pzh, rq;
            const result = rowObject.map((item) => {
              if (item["日期"]) {
                rq = item["日期"];
              }
              if (item["凭证号"]) {
                pzh = item["凭证号"];
              }
              return {
                ...item,
                凭证号: pzh,
                日期: rq,
                大写: convertCurrency(item["原币金额"])
              };
            });
            setData(result);
          });
        };
      }
      return false;
    }
    // showUploadList: false
  };
  const childrenWithProps = React.Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a
    // typescript error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { data });
    }
    return child;
  });
  return (
    <div className={Style.Container}>
      <Upload {...uploadProps}>
        <Button className={Style.btnStartInput}>选择excel</Button>
      </Upload>
      {childrenWithProps}
    </div>
  );
};
