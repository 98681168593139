import {ApolloClient} from 'apollo-client';
import {setContext} from 'apollo-link-context';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {BatchHttpLink} from 'apollo-link-batch-http';
import fetch from 'unfetch';

const httpLink = new BatchHttpLink({uri: 'https://gps.mrtangyun.com/api', fetch});

const authLink = setContext((_, {headers}) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('token');
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `${token}` : ''
        }
    };
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
});

export default client;
