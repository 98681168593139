import React, { useEffect, useState, useRef } from "react";
import { divide } from "lodash";
import { Query, compose, graphql } from "react-apollo";

import { Spin, Collapse, Button } from "antd";
import gql from "graphql-tag";
const { Panel } = Collapse;
export default QuestionOuter;
function QuestionOuter({ question }) {
  return (
    <Query
      query={gql`
        query chatgpt($words: String!) {
          chatgpt {
            prompt(words: $words) {
              id
              model
              object
              choices {
                text
                finish_reason
              }
            }
          }
        }
      `}
      variables={{ words: question }}
    >
      {({ loading, error, data, refetch, networkStatus, ...otherProp }) => {
        console.log({loading, error, data, networkStatus, ...otherProp});
        const refetchBtn = <Button loading={loading || networkStatus === 4} onClick={() => refetch()}>重试</Button>;
        let results;
        let showRefetch = true;
        let fistWordIsQuestion = false;
        if (!loading && data && data.chatgpt && data.chatgpt.prompt && data.chatgpt.prompt.choices) { 
          results = data.chatgpt.prompt.choices.map((item) => { 
            const { finish_reason, text } = item;
            if (finish_reason === 'stop') { 
              showRefetch = false;
            }
            let _text = text.replace('本内容由https://chatgpt.sbaliyun.com/独家提供!', '');
            if (/^(\?|？)\n{2}/.test(_text)) { 
              fistWordIsQuestion = true;
              _text = _text.replace(/^(\?|？)\n{2}/, '');
            }
            _text = _text.replace(/^\n{2}/, '');
            return _text
          });
        }
        return (
          <div
            style={{
              whiteSpace: "pre-wrap",
              color: "#fff"
            }}
          >
            <div
              style={{
                whiteSpace: "pre-wrap",
                background: "#3EACA8",
                padding: "0.5em"
              }}
            >
              {question} { fistWordIsQuestion ? '?' : ''}
              {loading && <Spin />}
              {
                !loading && showRefetch && refetchBtn
              }
            </div>
            {error && (
              <div
                style={{
                  background: "#547A82",
                  padding: "0.5em"
                }}
              >
                {error.graphQLErrors.map(({ message }) => (<div key={message}>{ message }</div>))}
              </div>
            )}
            {results &&
              results.map((text) => { 
                return (
                  <div
                    key={text}
                    style={{
                      whiteSpace: "pre-wrap",
                      background: "#5A5050",
                      padding: "0.5em"
                    }}
                  > {
                    text
                    }
                  </div>
                )
              })}
          </div>
        );
      }}
    </Query>
  );
}
