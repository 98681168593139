import React from 'react';
import Style from './app.module.scss';

const colorData = [
    ['#99b898', '#fecbac', '#ff847c', '#e84a5f', '#2a363b'],
    ['#f8b195', '#f6728D', '#cd6c84', '#6c5b7b', '#355c7d'],
    ['#a6206a', '#ec1c4b', '#f16a43', '#f6db68', '#2f9395'],
    ['#a8a7a8', '#cc527a', '#e8175d', '#474747', '#363636'],
    ['#acdbc9', '#dcebc2', '#ffd1b9', '#f7a7a6', '#f48b94'],
    ['#EF4655', '#F69A9A', '#F9CDAE', '#C8C8A9', '#83AE98'],
    ['#e6eec7', '#ece475', '#f9D423', '#f6903D', '#f05053'],
    ['#e9f0c7', '#a2d4ab', '#3eaca8', '#547a82', '#5a5050'],
];

export default class extends React.Component{
    state = {
        colorIndex: 0
    };

    changeColorIndexHandler = index => {
        this.setState({
            colorIndex: index
        });
    };
    render() {
        const {colorIndex} = this.state;
        return (
            <div className={Style.app}>
                <div className={Style.colorBoard}>
                    {
                        colorData[colorIndex].map((color, index) => (
                            <div key={index} style={{
                                backgroundColor: color,
                                position: 'relative'
                            }}>{color.toUpperCase()}
                            {
                                index === 0 && <span  style={{
                                    position: 'absolute',
                                    fontSize: '1.5em',
                                    top: 0
                                }}>web开发技术实践</span>
                            }
                            </div>
                        ))
                    }
                </div>
                <footer className={Style.Footer}>
                    {
                        colorData.map((colos, index) => (<div key={index} onClick={this.changeColorIndexHandler.bind(this, index)} className={colorIndex === index ? Style.current : ''}>

                            <div className={Style.colorBoard}>
                                {
                                    colorData[index].map((color, index) => (
                                        <div key={index} style={{
                                            backgroundColor: color
                                        }} />
                                    ))
                                }
                            </div>
                            </div>))
                    }
                </footer>
            </div>
        );
    }
}
