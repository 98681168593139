import React, { useEffect, useState, useRef } from "react";
import Style from "./index.module.scss";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { Upload, notification, Slider, Button, Checkbox } from "antd";
import { HeartOutlined } from "@ant-design/icons";
import avatars from "./avatars";

const isTest = false;
export default OrcOuter;
function OrcOuter() {
  return (
    <Query
      query={gql`
        query getOrcToken {
          baidubce {
            getToken
          }
        }
      `}
    >
      {({ loading, error, data }) => {
        if (loading) return <div>loading</div>;
        if (error) return <div>{error}</div>;
        return <Orc token={data.baidubce.getToken} />;
      }}
    </Query>
  );
}
function Orc({ token }) {
  const [likeCount, setLikeCount] = useState(30);
  const [hasUploaded, setHasUploaded] = useState(false);
  const [existdData, setExistdData] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [isOrcing, setIsOrcing] = useState(false);
  const [isShowNav, setIsShowNav] = useState(false);

  const title = isPreview ? "详情" : "设置朋友圈点赞数";
  const onChangeHideNav = (e) => {
    setIsShowNav(e.target.checked);
  };

  useEffect(() => {
    document.title = title;
  }, [isPreview]);

  const onChangeLikeCount = (newValue) => {
    setLikeCount(newValue);
  };
  useEffect(() => {
    const footer = document.getElementById("footer");
    footer && footer.remove();
  }, []);

  const uploadProps = {
    beforeUpload: async (file) => {
      if (/^image\//.test(file.type)) {
        setExistdData(null);
        setIsOrcing(true);
        setHasUploaded(true);
        const result = await toBase64(file);
        orc(result);
      }
      return false;
    },
    showUploadList: false
  };

  async function orc(base64) {
    var urlencoded = new URLSearchParams();
    urlencoded.append("detect_direction", "true");
    urlencoded.append("image", base64.replace(/^data:image\/[^,]+,/, ""));
    urlencoded.append("language_type", "CHN_ENG");

    const requestOptions = {
      method: "POST",
      body: urlencoded,
      redirect: "follow"
    };

    let re;
    const response = await fetch(
      "https://aip.baidubce.com/rest/2.0/ocr/v1/accurate?access_token=" + token,
      requestOptions
    );
    const result = await response.json();
    re = result.words_result;
    setIsOrcing(false);
    const isDetailPage = re.findIndex(({ words }) => words === "详情") > -1;
    if (!isDetailPage) {
      notification.error({ message: "请上传朋友圈详情页截图" });
      return;
    }
    let featureStyle = "删除";
    let index = re.findIndex(({ words }, index) =>
      // (/^20\d\d年\d\d?月\d\d?日/.test(words) &&
      //   re[index + 1].words === "删除") ||
      // (/^20\d\d年\d\d?月\d\d?日/.test(words) && words.endsWith("删除"))
      words.endsWith("删除")
    );
    if (index === -1) {
      featureStyle = "置顶";
      index = re.findIndex(({ words }, index) =>
        words.startsWith("置顶此朋友圈，将其长期展示在")
      );
    }
    if (index === -1) {
      featureStyle = "●●";
      index = re.findIndex(({ words }, index) => words === "●●");
    }
    if (index === -1) {
      featureStyle = null;
      notification.error({ message: "未找到自己朋友圈才有的“删除”字样特征" });
      return;
    }
    if (index > -1) {
      const imgEl = await loadImg(base64);
      let y_underDelete;
      switch (featureStyle) {
        case "删除":
          y_underDelete =
            re[index].location.top +
            re[index].location.height +
            re[index].location.height * 0.5;
          break;
        case "置顶":
        case "●●":
          y_underDelete =
            re[index].location.top +
            re[index].location.height +
            re[index].location.height * 2;
          break;

        default:
          break;
      }

      // 截图的原始宽度
      const width = imgEl.width;
      const height = imgEl.height;
      const canvas = document.createElement("canvas");
      canvas.style.width = "100%";
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(imgEl, 0, 0, width, height, 0, 0, width, height);

      let _y = 10;
      // 左上角像素点就是导航栏背景色
      const imgDataTeftTop = ctx.getImageData(0, _y, 1, 1);
      // 页面导航栏背景色
      const bgPageNav = `rgb(${imgDataTeftTop.data[0]}, ${imgDataTeftTop.data[1]}, ${imgDataTeftTop.data[2]})`;
      const isDark = imgDataTeftTop.data[0] < 100;
      const imgDataPageCenterBg = ctx.getImageData(0, y_underDelete, 1, 1);
      // 页面中间的背景色
      const bgPageCenter = `rgb(${imgDataPageCenterBg.data[0]}, ${imgDataPageCenterBg.data[1]}, ${imgDataPageCenterBg.data[2]})`;

      // 导航栏底部的y坐标
      let y_navBottom;
      while (!y_navBottom) {
        const imgData = ctx.getImageData(0, _y, 1, 1);
        if (isEqualImageData(imgDataPageCenterBg, imgData)) {
          y_navBottom = _y;
        }
        _y++;
      }
      // footer顶部的y坐标
      let y_footerTop;
      _y = y_underDelete;
      while (!y_footerTop && _y < height) {
        const imgData = ctx.getImageData(0, _y, 1, 1);
        if (!isEqualImageData(imgDataPageCenterBg, imgData)) {
          y_footerTop = _y;
        }
        _y++;
      }
      if (!y_footerTop) {
        y_footerTop = height;
      }
      console.log(
        0,
        bgPageNav,
        y_navBottom,
        bgPageCenter,
        y_underDelete,
        y_footerTop,
        height
      );

      const imgNav = createImgCanvas(imgEl, width, 0, y_navBottom, bgPageNav);
      const imgContentTop = createImgCanvas(
        imgEl,
        width,
        y_navBottom,
        y_underDelete,
        bgPageNav
      );
      const imgFooter = createImgCanvas(
        imgEl,
        width,
        y_footerTop,
        height,
        bgPageNav
      );
      // console.log(nav);
      // console.log(contentTop);
      setExistdData({
        imgNav,
        imgContentTop,
        imgFooter,
        bgPageCenter,
        likeContainerBg: isDark ? "#333" : "rgb(247, 247, 247)"
      });
      // document.body.appendChild(canvas);
      return;
    }
  }
  const _avatars = avatars.slice(
    0,
    typeof likeCount === "number" ? likeCount : 0
  );
  const preview = existdData && (
    <div
      style={{
        background: existdData.bgPageCenter
      }}
      className={Style.mockPageContainer}
    >
      <div
        className={Style.backSetting + " " + (isPreview ? Style.isPreview : "")}
        onClick={() => {
          setIsPreview(false);
        }}
      >
        <span>预览后，点击此处返回设置</span>
      </div>
      {isShowNav && (
        <div className={Style.nav}>
          <img src={existdData.imgNav} alt="" className={Style.w100} />
        </div>
      )}

      <div className={Style.contentScroll}>
        <div className={Style.imgContentTop}>
          <img src={existdData.imgContentTop} alt="" className={Style.w100} />
        </div>
        <div
          style={{
            background: existdData.likeContainerBg || "",
            color: existdData.likeContainerBg || "#fff"
          }}
          className={Style.likeContainer}
        >
          <span className={Style.triangle}></span>
          <div className={Style.likeIcon}>
            <HeartOutlined
              style={{
                color: "rgb(133, 138, 159)"
              }}
            />
          </div>

          <div className={Style.avatars}>
            {_avatars.map(({ img }, index) => (
              <div key={img + index} className={Style.item}>
                <div className={Style.radius}>
                  <img src={img} width="35" height="35" alt="" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={Style.footer}>
        <img src={existdData.imgFooter} alt="" className={Style.w100} />
      </div>
    </div>
  );
  if (isPreview) return preview;
  return (
    <div className={Style.pageContainer}>
      <div className={Style.row}>
        <div className={Style.key}>截图:</div>
        <div className={Style.value}>
          <Upload {...uploadProps}>
            <Button>朋友圈详情截图，一定要是自己的详情哦</Button>
            {/* <div className={Style.btnStartInput}>选择朋友圈截图</div> */}
          </Upload>
        </div>
      </div>

      <div className={Style.row}>
        <div className={Style.key}>点赞数:</div>
        <div className={Style.value}>
          <Slider
            min={1}
            max={avatars.length}
            onChange={onChangeLikeCount}
            value={typeof likeCount === "number" ? likeCount : 0}
          />
        </div>
      </div>

      <div className={Style.row}>
        <div className={Style.key}>导航栏:</div>
        <div className={Style.value}>
          <Checkbox checked={isShowNav} onChange={onChangeHideNav}>
            隐藏/显示
          </Checkbox>
        </div>
      </div>
      {hasUploaded && (
        <div className={Style.row}>
          <div className={Style.key}></div>
          <div className={Style.value}>
            <Button
              onClick={() => {
                setIsPreview(true);
              }}
              loading={isOrcing}
            >
              预览
            </Button>
          </div>
        </div>
      )}

      {preview}
    </div>
  );
}

async function loadImg(src) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve(img);
    };
    img.onerror = reject;
    img.src = src;
  });
}

function isEqualImageData(imgData1, imgData2) {
  if (imgData1.data.length !== imgData2.data.length) {
    return false;
  }
  for (let i = 0; i < imgData1.data.length; i++) {
    if (imgData1.data[i] !== imgData2.data[i]) {
      return false;
    }
  }
  return true;
}

function createImgCanvas(imgEl, width, startY, endY, bgPageCenter) {
  const canvas = document.createElement("canvas");
  const height = endY - startY;
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext("2d");
  canvas.width = width;
  canvas.height = height;
  if (bgPageCenter) {
    ctx.fillStyle = bgPageCenter;
    ctx.fillRect(0, 0, width, height);
  }
  ctx.drawImage(imgEl, 0, startY, width, height, 0, 0, width, height);
  return canvas.toDataURL();
}

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
