
import React, { useEffect, useState, useRef  } from 'react';
import AV from 'leancloud-storage';
import Style from './portal.module.scss';
import { Button, Modal, Input,message  } from 'antd';
import { customAlphabet} from 'nanoid'
import dayjs from 'dayjs'
const { TextArea } = Input;

const nanoid = customAlphabet('1234567890', 10);
const dataName = 'Protal';
export default function Portal() {
    
    const [sendText, setSendText] = useState('');
    const onChange = ({ target: { value } }) => {
        setSendText(value);
      };

    const [sendResult, setSendResult] = useState(null);


    const saveData = async (id) => {
        try {
            const ProtalObject = AV.Object.extend(dataName);
            const protalObject = new ProtalObject();
            protalObject.set('id', id);
            protalObject.set('payload', {
                type: 'text',
                data: sendText
            });
            protalObject.set('expiredAt', dayjs().add(2, 'day').toDate());
            await protalObject.save();
            message.success('发送成功');
            setSendResult(id);
        } catch (error) {
                console.log({
                    error
                })
        }
    }
    const [isModalSendOpen, setIsModalSendOpen] = useState(false);
    useEffect(() => {
        setSendText('')
        setSendResult(null)
    }, [isModalSendOpen]);

    const showModal = () => {
        setIsModalSendOpen(true);
    };

    const handleCancel = () => {
        setIsModalSendOpen(false);
    };

    const handleOk = async () => {
    if (sendResult) {
        handleCancel();
        return;
    }
    let id = nanoid(6);
    try {
        const query = new AV.Query(dataName);
        query.equalTo('id', id);
        const protalObjects = await query.find();
        if (protalObjects.length === 0) {
            saveData(id);
        } else { 
            message.error('id冲突');
        }
    } catch (error) {
        console.log({
            error
        });
        saveData(id);
    }
    
    };
    useEffect(() => {
        document.getElementById('footer').remove();
        // localStorage.setItem('debug', 'leancloud*');
        localStorage.removeItem('debug');
        AV.init({
            appId: "nzKh4Er9mW317lM1Yo0rcOaw-gzGzoHsz",
            appKey: "0rDCGis1HEMIpc69zXS8uPqa",
            serverURL: "https://db-leancloud-bluecoral.mrtangyun.com"
          });
    }, []);


    const [isModalReceiveOpen, setIsModalReceiveOpen] = useState(false);
    const [receiveResult, setReceiveResult] = useState(null);
    
    
    const [receiveCodeText, setReceiveCodeText] = useState('');
    
    const handleReceiveCancel = () => {
        setIsModalReceiveOpen(false);
    };
    useEffect(() => {
        setReceiveCodeText('');
        setReceiveResult(null);
    }, [isModalReceiveOpen]);
    const onReceiveCodeChange = ({ target: { value } }) => {
        setReceiveCodeText(value.toString());
    };
    const showReceiveModal = () => {
        setIsModalReceiveOpen(true);
    };

    const delExpiredData = async () => { 
        const query = new AV.Query(dataName);
        query.lessThan('expiredAt', dayjs().toDate());
        const expiredProtalObjects = await query.find();
        if (expiredProtalObjects.length) { 
            await AV.Object.destroyAll(expiredProtalObjects);
        }
    }
    const handleReceiveOk = async () => {
        if (receiveResult) {
            handleReceiveCancel();
            return;
        }
        try {
            await delExpiredData();
            const query = new AV.Query(dataName);
            query.equalTo('id', receiveCodeText.trim());
            const protalObjects = await query.find();
            if (protalObjects.length > 0) {
                const protalObject = protalObjects[0];
                const payload = protalObject.get('payload');
                
                if (payload.type === 'text') {
                    setReceiveResult(payload.data);
                }
            } else { 
                message.error('未找到数据');
            }
        } catch (error) {
            console.log({
                error
            });
        }
        // setIsModalReceiveOpen(false);
    };

    const clickCopyHandler = () => {
        let _input = document.createElement("input");
        _input.value = receiveResult;
        document.body.appendChild(_input);
        _input.select();
        document.execCommand("Copy");
        document.body.removeChild(_input);
        message.success('复制成功');
    };
    const clickCopySendResultHandler = () => {
        let _input = document.createElement("input");
        _input.value = `${window.location.href} 密码: ${sendResult}` ;
        document.body.appendChild(_input);
        _input.select();
        document.execCommand("Copy");
        document.body.removeChild(_input);
        message.success('复制成功');
    };
  return (
    <div className={Style.protalPage}>
      <div className={Style.buttons}>
        <div className={Style.btn} onClick={showModal}>发送</div>
        <div className={Style.btn} onClick={showReceiveModal}>接收</div>
      </div>
      <Modal
          title="发送文本"
          visible={isModalSendOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          okButtonProps={{ disabled: !sendText.trim() }}
        >
            {
                sendResult ? 
                <div className={Style.sendResult}>{sendResult}<Button onClick={clickCopySendResultHandler}>复制</Button></div>
                : <TextArea
                value={sendText}
                onChange={onChange}
                placeholder="Controlled autosize"
                autoSize={{ minRows: 3, maxRows: 10 }}
                />
            }
        </Modal>
        <Modal
          title="接收文本"
          visible={isModalReceiveOpen}
          onOk={handleReceiveOk}
          onCancel={handleReceiveCancel}
          okButtonProps={{ disabled: !(receiveCodeText.trim().length === 6) }}
        >
            {
                receiveResult ? 
                <div className={Style.sendResult}>
                    {receiveResult}
                    <Button onClick={clickCopyHandler}>复制</Button>
                    </div>
                : <Input type="tel"  onChange={onReceiveCodeChange}></Input>
            }
        </Modal>
      
    </div>
  );
}