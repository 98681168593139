import React from "react";
// import mock from "./data";
import Style from "./bank.module.scss";

export default ({ data, result, imgSrc }) => {
  // const result = mock.result;
  // console.log(result);
  // console.log(data);

  let huidan = {};
  const huidans = [];
  result.words_result.forEach((item, index) => {
    if (item.words.startsWith("付款人账号")) {
      huidan = {
        ...huidan,
        startLocation: item.location
      };
    }
    ["交易日期", "收款人全称"].forEach((key) => {
      if (item.words.startsWith(key)) {
        huidan = {
          ...huidan,
          [key]: result.words_result[index + 1].words
        };
      }
    });

    if (/\d{4}年\d{2}月\d{2}日/.test(item.words)) {
      huidan = {
        ...huidan,
        交易日期: item.words
          .replace(/年0?/, "/")
          .replace(/月0?/, "/")
          .replace(/日/, "")
      };
    } else if (item.words.startsWith("付款人全称")) {
      huidan = {
        ...huidan,
        付款人全称: result.words_result[index + 1].words.replace(
          /收款人全称.*/,
          ""
        )
      };
    } else if (/￥[\d,.]+/.test(item.words)) {
      const word = item.words.substring(item.words.indexOf("¥") + 1);
      huidan = {
        ...huidan,
        小写: Number(word.replace(/,/g, "").replace(/￥/, ""))
      };
    } else if (/^人民币.{3,}/.test(item.words)) {
      huidan = {
        ...huidan,
        金额: item.words.replace(/人民币/, "")
      };
    } else if (item.words.endsWith("有限公司")) {
      huidan = {
        ...huidan,
        // 待定付款方， 付款方目前只考虑“有限公司”结尾的
        daidingFkf: huidan.daidingFkf ? [...huidan.daidingFkf, item] : [item]
      };
    }

    if (item.words.startsWith("打印状态")) {
      const location = result.words_result[index + 1].location;
      huidan = {
        ...huidan,
        endLocation: location,
        location: {
          top: huidan.startLocation.top,
          left: huidan.startLocation.left,
          width: location.left + location.width - huidan.startLocation.left,
          height: location.top + location.height - huidan.startLocation.top
        }
      };
      huidan = {
        ...huidan,
        location: {
          ...huidan.location,
          center_x: huidan.location.left + huidan.location.width / 2,
          center_y: huidan.location.top + huidan.location.height / 2
        }
      };
      // 处理收款人全称, 目前只考虑在单据的右侧
      if (huidan.daidingFkf) {
        huidan.daidingFkf.forEach((item) => {
          if (item.location.left > huidan.location.center_x) {
            huidan["收款人全称"] = item.words;
          }
        });
      }
      delete huidan.startLocation;
      delete huidan.endLocation;
      delete huidan.daidingFkf;
      huidans.push({
        ...huidan
      });
      huidan = {};
    }
  });
  if (data && huidans.length) {
    huidans.forEach((huidan) => {
      const index = data.findIndex((o) => {
        return (
          (o["原币金额"] === huidan["小写"] || o["大写"] === huidan["金额"]) &&
          o["日期"] === huidan["交易日期"] &&
          o["摘要"].indexOf(huidan["收款人全称"]) > -1
        );
      });

      if (index > -1) {
        huidan["凭证号"] = data[index]["凭证号"];
      } else {
        const index = data.findIndex((o) => {
          return (
            (o["原币金额"] === huidan["小写"] ||
              o["大写"] === huidan["金额"]) &&
            o["日期"] === huidan["交易日期"]
          );
        });
        if (index > -1) {
          huidan["凭证号"] = data[index]["凭证号"];
          huidan.pzh_daiding = "收款人";
        } else {
          const index = data.findIndex((o) => {
            return (
              (o["摘要"] || "").indexOf(huidan["收款人全称"]) > -1 &&
              o["日期"] === huidan["交易日期"]
            );
          });
          if (index > -1) {
            huidan["凭证号"] = data[index]["凭证号"];
            huidan.pzh_daiding = "金额";
          }
        }
      }
    });
  }
  console.log(huidans);

  // useEffect(() => {
  //   const ctx = canvas.current.getContext("2d");
  //   const img = new Image();
  //   img.src = imgSrc;
  //   img.onload = () => {
  //     canvas.current.width = img.width;
  //     canvas.current.height = img.height;
  //     ctx.drawImage(img, 0, 0);
  //     huidans.forEach((huidan, index) => {
  //       if (huidan["凭证号"] && !huidan.pzh_daiding) {
  //         ctx.fillStyle = "rgba(255,165,0,1)";
  //       }
  //       ctx.fillRect(
  //         huidan.location.left,
  //         huidan.location.top,
  //         huidan.location.width,
  //         huidan.location.height
  //       );

  //       ctx.fillStyle = "green";
  //       ctx.fillText(
  //         `凭证号：${huidan["凭证号"]}`,
  //         huidan.location.left,
  //         huidan.location.top
  //       );
  //     });
  //   };
  // }, [data, imgSrc, result]);
  return (
    <div
      className={Style.Container}
      style={{
        position: "absolute",
        top: 0,
        left: 0
      }}
    >
      {/* <canvas ref={canvas} /> */}
      {imgSrc && <img src={imgSrc} alt="" />}
      {huidans.map((huidan, index) => {
        let huidanCss = {};
        if (huidan["凭证号"] && !huidan.pzh_daiding) {
          huidanCss = {
            fontSize: "5em",
            color: "white",
            fontWeight: "500",
            backgroundColor: `rgba(0, 128, 0, 0.5)`
          };
        } else if (huidan["凭证号"] && huidan.pzh_daiding) {
          huidanCss = {
            fontSize: "1.2em",
            color: "red",
            backgroundColor: `rgb(65 145 245 / 50%)`
          };
        }
        return (
          <div
            key={`huidan-${index}`}
            className={Style.huidan}
            style={{
              top: huidan.location.top + "px",
              left: huidan.location.left + "px",
              width: huidan.location.width + "px",
              height: huidan.location.height + "px",
              fontSize: "1.2em",
              ...huidanCss
            }}
          >
            {huidan.pzh_daiding && <div>{huidan.pzh_daiding}待核对</div>}
            {huidan["凭证号"] && <div>凭证号：{huidan["凭证号"]}</div>}
            {huidan.pzh_daiding &&
              Object.keys(huidan)
                .filter((key) => key !== "location" && key !== "凭证号")
                .map((key) => (
                  <div key={key}>
                    {key}:{JSON.stringify(huidan[key])}
                  </div>
                ))}
          </div>
        );
      })}
      {/*
        result.words_result.map((item, index) => {
        return (
          <div
            key={index}
            className={Style.text}
            style={{
              top: item.location.top + "px",
              left: item.location.left + "px",
              width: item.location.width + "px",
              height: item.location.height + "px"
            }}
          >
            {item.words}
          </div>
        );
      })
    */}
    </div>
  );
};
