import "babel-polyfill";
import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import ColorPage from "./pages/colors/App";
import PortalPage from "./pages/portal/index";
import OrcPage from "./pages/orc/index";
import PyqPage from "./pages/pyq/index";
import SudokuPage from "./pages/sudoku/index";
import AiPage from "./pages/ai/index";
import * as serviceWorker from "./serviceWorker";
import client from "./apolloClient";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { ApolloProvider } from "react-apollo";

const root = createRoot(document.getElementById("root"));
root.render(
  <ApolloProvider client={client}>
    <Router>
      <Switch>
        <Route path="/color" component={ColorPage} />
        <Route path="/p" component={PortalPage} />
        <Route path="/ai" component={AiPage} />
        <Route path="/orc" component={OrcPage} />
        <Route path="/pyq" component={PyqPage} />
        <Route path="/sudoku" component={SudokuPage} />
        <Route component={ColorPage} />
      </Switch>
    </Router>
  </ApolloProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
