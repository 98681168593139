import React, { useEffect, useState, useRef } from "react";
import { divide } from "lodash";
import Style from "./orc.module.scss";
import { Query, compose, graphql } from "react-apollo";
import gql from "graphql-tag";
import { Upload, notification } from "antd";
import BankChangsha from "./bankChangsha";
import UploadExcel from "./uploadExcel";
// export default
// export default () => {
//   return (
//     <UploadExcel>
//       <BankChangsha />
//     </UploadExcel>
//   );
// };
export default OrcOuter;
function OrcOuter() {
  return (
    <Query
      query={gql`
        query getOrcToken {
          baidubce {
            getToken
          }
        }
      `}
    >
      {({ loading, error, data }) => {
        if (loading) return <div>loading</div>;
        if (error) return <div>{error}</div>;
        return (
          <UploadExcel>
            <Orc token={data.baidubce.getToken} />
          </UploadExcel>
          // <ShowResult />
        );
      }}
    </Query>
  );
}
function Orc({ token, data }) {
  useEffect(() => {
    const footer = document.getElementById("footer");
    footer && footer.remove();
  }, []);
  const video = useRef(null);
  const canvas = useRef(null);
  const [status, setStatus] = useState("init");
  const [base64Src, setBase64Src] = useState(null);
  const [orcResult, setOrcResult] = useState(null);
  const clickStopCameraHandler = () => {
    setStatus("stop");
    const stream = video.current.srcObject;
    const tracks = stream.getTracks();
    tracks.forEach(function (track) {
      track.stop();
    });
    video.current.srcObject = null;
  };
  const clickPauseHandler = () => {
    setStatus("play");
    setBase64Src(null);
    setOrcResult(null);
    video.current.play();
  };
  const clickRestartHandler = () => {
    setStatus("play");
    setBase64Src(null);
    setOrcResult(null);
    video.current.play();
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const uploadProps = {
    beforeUpload: (file) => {
      if (/^image\//.test(file.type)) {
        toBase64(file).then((result) => {
          setBase64Src(result);
          orc(result);
        });
      }
      return false;
    },
    showUploadList: false
  };

  const clickStartHandler = () => {
    const constraints = {
      audio: false,
      video: {
        facingMode: { exact: "environment" },
        width: { min: 1280 },
        height: { min: 720 }
      }
    };

    function handleError(error) {
      console.log(
        "navigator.MediaDevices.getUserMedia error: ",
        error.message,
        error.name
      );
      notification.open({
        message: error.name,
        description: error.message,
        onClick: () => {
          console.log("Notification Clicked!");
        }
      });
    }

    function handleSuccess(stream) {
      window.stream = stream; // make stream available to browser console
      video.current.srcObject = stream;
      canvas.current = document.createElement("canvas");
      canvas.current.width = video.current.videoWidth;
      canvas.current.height = video.current.videoHeight;
      setStatus("play");
      const ctx = canvas.current.getContext("2d");
      const draw = () => {
        if (!video.current) {
          requestAnimationFrame(draw);
        } else {
          canvas.current.width = video.current.videoWidth;
          canvas.current.height = video.current.videoHeight;
          ctx.drawImage(
            video.current,
            0,
            0,
            canvas.current.width,
            canvas.current.height
          );
          requestAnimationFrame(draw);
        }
      };
      window.requestAnimationFrame(draw);
    }
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(handleSuccess)
      .catch(handleError);
  };

  function clickSnapshotHandler() {
    setOrcResult(null);

    setStatus("pause");
    video.current.pause();
    // const fullQuality = canvas.toDataURL('image/jpeg', 1.0);
    const base64 = canvas.current.toDataURL("image/jpeg", 1.0);
    // const lowQuality = canvas.toDataURL('image/jpeg', 0.1);
    setBase64Src(base64);
    orc(base64);
  }

  function orc(base64) {
    var urlencoded = new URLSearchParams();
    urlencoded.append("detect_direction", "true");
    urlencoded.append("image", base64.replace(/^data:image\/[^,]+,/, ""));
    urlencoded.append("language_type", "CHN_ENG");

    var requestOptions = {
      method: "POST",
      body: urlencoded,
      redirect: "follow"
    };

    fetch(
      "https://aip.baidubce.com/rest/2.0/ocr/v1/accurate?access_token=" + token,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setOrcResult(result);
      })
      .catch((error) => console.log("error", error));
  }
  const showBankChangsha =
    data &&
    base64Src &&
    orcResult &&
    orcResult.words_result.some(
      ({ words }) => words === "长沙银行单位客户专用回单"
    );
  return (
    <div className={Style.pageContainer}>
      <video playsInline autoPlay ref={video} className={Style.video}></video>
      {showBankChangsha && (
        <BankChangsha data={data} result={orcResult} imgSrc={base64Src} />
      )}

      {/* <ShowResult result={orcResult} imgBase64={base64Src} /> */}
      {status === "init" && (
        <>
          <Upload {...uploadProps}>
            <div className={Style.btnStartInput}>相册</div>
          </Upload>
          <div className={Style.btnStart} onClick={clickStartHandler}>
            摄像头
          </div>
        </>
      )}
      {status === "stop" && (
        <>
          <Upload {...uploadProps}>
            <div className={Style.btnStartInput}>图片</div>
          </Upload>
          <div className={Style.btnStart} onClick={clickRestartHandler}>
            摄像头
          </div>
        </>
      )}
      {status === "pause" && (
        <div className={Style.cameraBtns}>
          <div className={Style.btn} onClick={clickPauseHandler}>
            继续
          </div>
          <div className={[Style.btnStop]} onClick={clickStopCameraHandler}>
            停止
          </div>
        </div>
      )}
      {status === "play" && (
        <div className={Style.cameraBtns}>
          <div className={Style.btn} onClick={clickSnapshotHandler}>
            拍照
          </div>
          <div className={[Style.btnStop]} onClick={clickStopCameraHandler}>
            停止
          </div>
        </div>
      )}
    </div>
  );
}

function ShowResult({ result, imgBase64 }) {
  console.log(result);
  if (!result || !imgBase64) return null;
  const { words_result } = result;
  return (
    <div className={Style.orcResultContainer}>
      <img src={imgBase64} alt="" />
      {words_result.map((item, index) => {
        return (
          <div
            key={index}
            className={Style.text}
            style={{
              top: item.location.top + "px",
              left: item.location.left + "px",
              width: item.location.width + "px",
              height: item.location.height + "px"
            }}
          >
            {item.words}
          </div>
        );
      })}
    </div>
  );
}
