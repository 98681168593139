
import React, { useState, useEffect, useRef  } from 'react';

import Style from './sudoku.module.scss';
const code = `self.onmessage = (e) => {
  const size = e.data
  
  const _getNewRandowArr = (size) => {
    return new Array(size)
      .fill(0)
      .map((_, index) => index + 1)
      .sort(() => Math.random() - 0.5);
  };

  const getIndex = (row, col, size) => {
    return row * size + col;
  };

  // 获取所有维度的数据
  const getAllDimensionByBoard = (board) => {
    const size = Math.sqrt(board.length);
    return {
      boardByRow: new Array(size).fill(0).map((_, index) => {
        return board.filter(({ r }) => r === index).map(({ v }) => v);
      }),
      boardByCol: new Array(size).fill(0).map((_, index) => {
        return board.filter(({ c }) => c === index).map(({ v }) => v);
      }),
      boardByGong: new Array(size).fill(0).map((_, index) => {
        return board.filter(({ g }) => g === index).map(({ v }) => v);
      }),
    };
  };
  // 根据行列索引， 获取单个单元格所有维度的现有数据
  const getAllDimensionByRowAndCol = (
    board,
    rowIndex,
    colIndex
  ) => {
    const size = Math.sqrt(board.length);
    const _small_size =  Math.sqrt(size);
    let _gongIndex =
      Math.floor(rowIndex / _small_size) * _small_size +
      Math.floor(colIndex / _small_size);
    return {
      boardByRow: board.filter(({ r }) => r === rowIndex).map(({ v }) => v),
      boardByCol: board.filter(({ c }) => c === colIndex).map(({ v }) => v),
      boardByGong: board.filter(({ g }) => g === _gongIndex).map(({ v }) => v),
    };
  };

  const getRestRandomNumbersByRowAndCol = (
    board,
    rowIndex,
    colIndex
  ) => {
    const size = Math.sqrt(board.length);
    const _small_size =  Math.sqrt(size);
    const allDimensions = getAllDimensionByRowAndCol(
      board,
      rowIndex,
      colIndex
    );
    const allExist = Array.from(
      new Set([
        ...allDimensions.boardByCol,
        ...allDimensions.boardByGong,
        ...allDimensions.boardByRow,
      ])
    );
    const result = [];
    for (let index = 0; index < _small_size * _small_size; index++) {
      if (!allExist.includes(index + 1)) {
        result.push(index + 1);
      }
    }
    return result;
  };
  const createNewBoard3 = (size) => {
    // 先生成斜对角线的数组， 然后按照顺序， 排除已经有的数字，随机放入一个，
    const _small_size = Math.sqrt(size);
    const board = [];
    ((_board) => {
      let _rowIndex = 0;
      let _colIndex = 0;
      for (let index = 0; index < size * size; index++) {
        let _gongIndex =
          Math.floor(_rowIndex / _small_size) * _small_size +
          Math.floor(_colIndex / _small_size);
          _board.push({
          r: _rowIndex,
          c: _colIndex,
          g: _gongIndex,
          i: index,
          v: "",
        });
        _colIndex++;
        if (_colIndex === size) {
          _rowIndex++;
          _colIndex = 0;
        }
      }
    })(board);
    ((_board) => {
      for (let index = 0; index < _small_size; index++) {
        const data = _getNewRandowArr(size);
        let _rowIndex = index * _small_size;
        let _colIndex = index * _small_size;
        for (let i = _rowIndex; i < _rowIndex + _small_size; i++) {
          for (let j = _colIndex; j < _colIndex + _small_size; j++) {
            // console.log(i, j, getIndex(i, j, size));
            _board[getIndex(i, j, size)].v = data.shift();
            _board[getIndex(i, j, size)].isSolid = true;
          }
        }
      }
    })(board);

    ((_board, _small_size) => {
      let handlerCells = [];
      let backIndex;
      for (let index = 0; index < _board.length; index++) {
        const cell = _board[index];
        if (cell.isSolid) {
          continue;
        }
        // console.log('current index', index);
        
        let restRandomNumbers;
        if (cell.restRandomNumbers && cell.restRandomNumbers.length) {
          restRandomNumbers = cell.restRandomNumbers;
        } else {
          restRandomNumbers = getRestRandomNumbersByRowAndCol(
            _board,
            cell.r,
            cell.c
          );
        }
        if (restRandomNumbers.length === 0 ) {
          // debugger;
          // 没有找到合适的数字, 需要回退
          // index = 0;
          const last = handlerCells.splice(handlerCells.length - 1)[0];
          index = last.i - 1 ;
          backIndex = index;
          // debugger;
          // 回退后的单元格， 可选数组全部清除
          for (let _i = last.i + 1; _i < _board.length; _i++) {
            delete _board[_i].restRandomNumbers;
            if (!_board[_i].isSolid) {
              _board[_i].v = '';
            }
          }
          // debugger;
          // console.error('没有找到合适的数字, 回退到' + index);
        } else {
          if (restRandomNumbers.length === 1) {
            // 如果只有一个数字， 那么就不用回退到这里了
            cell.v = restRandomNumbers[0];
            cell.restRandomNumbers = [];
          } else {
            cell.v = restRandomNumbers.splice(0, 1)[0];
            cell.restRandomNumbers = restRandomNumbers;
          }
          if (cell.restRandomNumbers.length) {
            handlerCells.push(cell);
          } 
        }
      }
     
    })(board, _small_size);
    
    return board;
  };
  let _board = createNewBoard3(size);
  self.postMessage(_board)
}`;

const _createBlobObjectURL = (code) => {
  const blob = new Blob([`${code}`], { type: "text/javascript" });
  const url = URL.createObjectURL(blob);
  return url;
};

  // 获取所有维度的数据
  const getAllDimensionByBoard = (board) => {
    const size = Math.sqrt(board.length);
    return {
      boardByRow: new Array(size).fill(0).map((_, index) => {
        return board.filter(({ r }) => r === index).map(({ v }) => v);
      }),
      boardByCol: new Array(size).fill(0).map((_, index) => {
        return board.filter(({ c }) => c === index).map(({ v }) => v);
      }),
      boardByGong: new Array(size).fill(0).map((_, index) => {
        return board.filter(({ g }) => g === index).map(({ v }) => v);
      }),
    };
  };

export default () => { 
  const [size, setSize] = useState(16);
  const [board, setBoard] = useState([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const worker = useRef(null);
  const [frameIndex, setFrameIndex] = useState(0);
  
  useEffect(() => {
    // console.log('useEffect')
    // const draw = () => {
    //   setFrameIndex(index => index + 1)
    //   requestAnimationFrame(draw)
    // };
    // window.requestAnimationFrame(draw)
  }, []);

  const clickG = () => {
    if (!Number.isInteger(Math.sqrt(size))) {
      throw new Error('size 必须是平方数');
    }
    if (!worker.current) {
      const url = _createBlobObjectURL(code);
      worker.current = new Worker(url);
    }

    setBoard([]);
    setIsGenerating(true);
    const handler = (e) => {
      const board = e.data;
      worker.current.removeEventListener("message", handler);
      console.log('handler');
      console.timeEnd("getNewBoard" + size);
      setBoard(getAllDimensionByBoard(board).boardByRow);
      setIsGenerating(false);
    }
    worker.current.addEventListener("message", handler);
    console.time("getNewBoard" + size);
    worker.current.postMessage(size);
  };
  const sqrtSize = Math.sqrt(size);
  return (
    <div className="App">
      {frameIndex}
      {
        isGenerating ? <div>正在生成中...</div> : <div onClick={clickG}>点击生成</div>
      }
      {
          board.length && (
              <div className={ Style.table}>
                  {
                      board.map((row, rowIndex) => {
                        return (
                            <div className={Style.row + ' ' + ((rowIndex > 0 && rowIndex % sqrtSize) === 0 ? Style.topLine : '' )} key={rowIndex}>
                                    {
                                        row.map((i, colIndex) => (<div className={ Style.td + ' ' + ((colIndex > 0 && colIndex % sqrtSize) === 0 ? Style.leftLine : '' )} key={i}>{ i}</div>))
                                    }
                            </div>
                        );
                      })
                  }
              </div>
          )
      }
      
    </div>
  );
}